import type { GetCollectionQuery } from '@/shopify-storefront'
import { GetCollectionDocument } from '@/graphql/shopify/storefront'

export const useCollectionQuery = (handle: string) => {
  return useAsyncQuery<GetCollectionQuery>({
    query: GetCollectionDocument,
    variables: { handle },
    clientId: 'shopify',
  })
}
